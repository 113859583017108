/** @format */

import React, { useEffect, useState } from "react";
import { showNotification } from "@mantine/notifications";
import {
  ActionIcon,
  Anchor,
  Button,
  Center,
  Skeleton,
  Table,
  Pagination,
  Modal,
  Badge,
  Text,
} from "@mantine/core";
import { Refresh } from "tabler-icons-react";
import smartTruncate from "smart-truncate";
import { formatDistance, parseISO } from "date-fns";
import { formatDate, loadingStates } from "../../utilities/utilities";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { getResourceTasks, updateCompleteFlag } from "../../utilities/apis/tasks";
import TaskNewForm from "../CaseNewUI/TaskNewForm";
import { taskStatusColors } from "../../utilities/enums";
import UserAvatarView from "../UserAvatarView";
import TaskDetailsUI from "../TaskNewUI/TaskDetailsUI";
import TaskReviewForm from "./TaskReviewForm";

const TasksTab = ({ parent, parentId, title }) => {
  const [tasksConfig, setTasksConfig] = useState({
    reviweFormVisible: false,
    taskFormVisible: false,
    loading: loadingStates.NO_ACTIVE_REQUEST,
    tasks: [],
    page: 1,
    tasksCount: null,
    loadTask: null,
    loadRemark: null,
    completionFlagBtnLoading: false
  });

  const getTasks = async () => {
    setTasksConfig((taskC) => ({
      ...taskC,
      loading: loadingStates.LOADING,
      tasksCount: null,
    }));
    const response = await apiWrapWithErrorWithData(
      getResourceTasks({
        parentResource: parent,
        parentResourceId: parentId,
        page: tasksConfig.page,
      })
    );
    if (response?.success && response?.tasks) {
      setTasksConfig({
        ...tasksConfig,
        tasks: response.tasks,
        loading: loadingStates.NO_ACTIVE_REQUEST,
        tasksCount: response.tasksCount || null,
      });
    } else {
      // showNotification({
      //   color: 'red',
      //   title: 'Tasks',
      //   message: `Failed to load tasks for ${parent} ${parentId}`,
      // });
      setTasksConfig({
        ...tasksConfig,
        loading: loadingStates.NO_ACTIVE_REQUEST,
      });
    }
  };

  useEffect(() => {
    if (tasksConfig.loadTask === null || !tasksConfig.taskFormVisible) {
      getTasks();
    }
  }, [tasksConfig.loadTask, tasksConfig.taskFormVisible, tasksConfig.page]);

  const submitReview = async (completionFlag) => {
    setTasksConfig({ ...tasksConfig, completionFlagBtnLoading: true });
    try {
  
      const response = await apiWrapWithErrorWithData(updateCompleteFlag({ taskId: tasksConfig.loadRemark, isCompleted: completionFlag }));
      
      if(response.success) {
        showNotification({
          color: 'green',
          title: 'Tasks',
          message: `Task is marked as ${completionFlag ? "completed": "uncompleted"}`,
        });
      } else {
        showNotification({
          color: 'red',
          title: 'Tasks',
          message: response.message,
        });
      }
    } catch(error) {
      console.log({ error });
      showNotification({
        color: 'red',
        title: 'Tasks',
        message: `Unable to make it as completed`,
      });
    }
    setTasksConfig({ ...tasksConfig, reviweFormVisible: false, loadRemark: null, completionFlagBtnLoading: false });
  };

  return (
    <>
      {tasksConfig.taskFormVisible && (
        <Modal
          closeOnClickOutside={false}
          overflow="inside"
          opened
          onClose={() => {
            setTasksConfig({
              ...tasksConfig,
              taskFormVisible: false,
            });
          }}
          size="calc(80vw)"
        >
          <TaskNewForm
            title={title}
            parent={parent}
            parentId={parentId}
            onModalExit={() => {
              setTasksConfig({
                ...tasksConfig,
                taskFormVisible: false,
              });
            }}
          />
        </Modal>
      )}
      {tasksConfig.reviweFormVisible ? (
        <Modal 
          closeOnClickOutside={true}
          overflow="inside"
          opened
          onClose={() => {
            setTasksConfig({
              ...tasksConfig,
              reviweFormVisible: false,
              loadRemark: null
            });
          }}
        >
          <TaskReviewForm 
            loading={tasksConfig.completionFlagBtnLoading}
            onSubmit={submitReview}
          />
        </Modal>
      ) : null}

      {tasksConfig.loadTask !== null && (
        <Modal
          overflow="inside"
          opened
          onClose={() => {
            setTasksConfig({
              ...tasksConfig,
              loadTask: null,
            });
          }}
          size="calc(80vw)"
        >
          <TaskDetailsUI
            title={title}
            parent={parent}
            parentId={parentId}
            taskId={tasksConfig.loadTask}
            onModalExit={() => {
              setTasksConfig({
                ...tasksConfig,
                loadTask: null,
              });
            }}
          />
        </Modal>
      )}
      <div className="flex flex-col p-4">
        <div className="flex flex-row justify-end">
          <div className="flex items-center">
            <ActionIcon color="white" className="mx-2" onClick={getTasks}>
              <Refresh />
            </ActionIcon>
            <Button
              onClick={() =>
                setTasksConfig({
                  ...tasksConfig,
                  taskFormVisible: true,
                })
              }
              className="w-40 mx-2"
              style={{
                backgroundColor: "#46BDE1",
              }}
            >
              Create Task
            </Button>
          </div>
        </div>
        {tasksConfig.loading === loadingStates.LOADING && (
          <div className="flex flex-col mb-2 mt-8">
            <Skeleton height={30} radius="md" className="my-1 w-full" />
            <Skeleton height={30} radius="md" className="my-1 w-full" />
            <Skeleton height={30} radius="md" className="my-1 w-full" />
            <Skeleton height={30} radius="md" className="my-1 w-full" />
            <Skeleton height={30} radius="md" className="my-1 w-full" />
            <Skeleton height={30} radius="md" className="my-1 w-full" />
            <Skeleton height={30} radius="md" className="my-1 w-full" />
            <Skeleton height={30} radius="md" className="my-1 w-full" />
            <Skeleton height={30} radius="md" className="my-1 w-full" />
            <Skeleton height={30} radius="md" className="my-1 w-full" />
            <Skeleton height={30} radius="md" className="my-1 w-full" />
            <Skeleton height={30} radius="md" className="my-1 w-full" />
            <div className="flex flex-row justify-center">
              <Skeleton height={40} radius="md" className="w-40" />
            </div>
          </div>
        )}
        {tasksConfig.loading !== loadingStates.LOADING &&
          !tasksConfig.tasks?.length && (
            <Center className="my-4">No tasks to show</Center>
          )}
        {tasksConfig.loading !== loadingStates.LOADING &&
          !!tasksConfig.tasks.length && (
            <Table className="mt-8" striped>
              <thead>
                <tr>
                  <th>Reference</th>
                  <th>Due date</th>
                  <th>Created by</th>
                  <th>Assigned to</th>
                  <th>Status</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {tasksConfig.tasks.map((row) => (
                  <tr key={row.id}>
                    <td>
                      <div className="flex flex-col">
                        <div>{`Task - ${row.id}`}</div>
                        <Text size="xs" color="gray">
                          {smartTruncate(row.title, 20)}
                        </Text>
                      </div>
                    </td>
                    <td>
                      <div className="flex flex-col">
                        <span>{formatDate(row.dueDate)}</span>
                        <Text color="gray" size="xs">
                          {formatDistance(parseISO(row.dueDate), new Date(), {
                            addSuffix: true,
                          })}
                        </Text>
                      </div>
                    </td>
                    <td>
                      <UserAvatarView {...row.createdBy} />
                    </td>
                    <td>
                      {/* <UserAvatarView {...row.assignedTo} /> */}
                      <div>{row.assignEmailTo}</div>
                    </td>
                    <td>
                      <Badge color={taskStatusColors[row.status] || "orange"}>
                        {row.status}
                      </Badge>
                    </td>
                    <td>
                      <Anchor
                        onClick={(e) => {
                          e.stopPropagation();
                          setTasksConfig({
                            ...tasksConfig,
                            loadTask: row.id,
                          });
                        }}
                      >
                        View
                      </Anchor>
                    </td>
                    <td>
                      <Anchor
                        onClick={(e) => {
                          e.stopPropagation();
                          setTasksConfig({
                            ...tasksConfig,
                            reviweFormVisible: true,
                            loadRemark: row.id
                          });
                        }}
                      >
                        Review
                      </Anchor>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        {tasksConfig.tasksCount && (
          <div className="flex justify-center mt-4">
            <Pagination
              onChange={(page) => {
                setTasksConfig({
                  ...tasksConfig,
                  page,
                });
              }}
              total={Math.ceil(tasksConfig.tasksCount / 10)}
              page={tasksConfig.page}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default TasksTab;
