import React from "react";
import styles from "./AgreementDetail.module.css";

function PartyDetail({ party, agreementData }) {
  return (
    <div className="grid grid-cols-4 gap-y-9 gap-x-4 mt-3 mb-9">
      {!(party.entityType) && !agreementData.data.json.isLegacy && (
        <>
          <div>
            <div className={styles.label}>individual name</div>
            <div className={styles.text}>{party.indi_name}</div>
          </div>
          <div>
            <div className={styles.label}>email</div>
            <div className={styles.text}>{party.indi_email}</div>
          </div>
        </>
      )}
      {!(party.entityType) && agreementData.data.json.isLegacy && (
        <>
          <div>
            <div className={styles.label}>individual name</div>
            <div className={styles.text}>{party.indi_name}</div>
          </div>
          {/* <div>
            <div className={styles.label}>Email ID</div>
            <div className={styles.text}>{party.indi_email}</div>
          </div> */}
        </>
      )}
      {party.entityType && !agreementData.data.json.isLegacy && (
        <>
          <div>
            <div className={styles.label}>entity type</div>
            <div className={styles.text}>{party.entityType}</div>
          </div>
          <div>
            <div className={styles.label}>registered name </div>
            <div className={styles.text}>{party.name}</div>
          </div>
          <div>
            <div className={styles.label}>authorised signatory name </div>
            <div className={styles.text}>{party.signatory}</div>
          </div>
          <div>
            <div className={styles.label}>authorised signatory email </div>
            <div className={styles.text}>{party.email}</div>
          </div>
        </>
      )}
      {party.entityType && agreementData.data.json.isLegacy && (
        <>
          <div>
            <div className={styles.label}>entity type</div>
            <div className={styles.text}>{party.entityType}</div>
          </div>
          <div>
            <div className={styles.label}>registered name </div>
            <div className={styles.text}>{party.name}</div>
          </div>
          {/* <div>
            <div className={styles.label}>Authorised Signatory Name </div>
            <div className={styles.text}>{party.signatory}</div>
          </div>
          <div>
            <div className={styles.label}>Authorised Signatory Email ID </div>
            <div className={styles.text}>{party.email}</div>
          </div> */}
        </>
      )}
    </div>
  );
}

export default PartyDetail;
