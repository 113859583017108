import React from "react";
import { Template, Edit, ReportMoney, Users } from "tabler-icons-react";
import styles from "./Section3.module.scss";
import logo from "./assets/icons8-visa-stamp-30.png";
import logo2 from "./assets/agreement-module.svg";
import logo3 from "./assets/icons8-visa-stamp-30.png";
import logo4 from "./assets/MobileDisputeImage.svg";
import logo5 from "./assets/icons8-digital-signature-48.png";
import logo6 from "./assets/icons8-digital-signature-48.png";


function Section3() {
  return (
    <div>
      <div
        className={`${styles.featureBox} w-full pt-16 flex flex-col pb-24 mt-11 hiddenOnMobile`}
      >
        <div className={`ml-16 mb-3 ${styles.featureHead}`}>
          AGREEMENT MANAGER
        </div>
        <div className={`${styles.featureHeadMain} ml-16 mb-4`}>
          Create, negotiate, execute and store your company&apos;s agreements
        </div>
        <div className="flex flex-row ml-16 mt-4">
          <div className="w-3/5">
            <div className={`${styles.title} mt-3`}>
              Use our Templates Library to execute agreements easily and faster
              , Onboard employees, partners, distributors, vendors etc.
              digitally within seconds
            </div>
            <div className="grid grid-cols-2 mt-16 gap-x-20 gap-y-10 pr-20">
              <div className="flex flex-row items-start">
                <div>
                  <Template />
                </div>
                <div className="flex flex-col ml-4">
                  <div className={styles.label}>Templates Library</div>
                  <div className={`${styles.text} mt-2`}>
                    Use our carefully curated library of standardised templates
                    to execute agreements faster and without the help of legal
                    professionals
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-start">
                <div>
                  <Edit />
                </div>
                <div className="flex flex-col ml-4">
                  <div className={styles.label}>Online Editor</div>
                  <div className={`${styles.text} mt-2`}>
                    Create legal documents such as legal notices and agreements
                    using our online editor which allows you to create result
                    oriented documents even if you do not know complexities of
                    Microsoft or other conventional editing tools.
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-start">
                <img
                  src={logo5}
                  width="24px"
                  alt="rectanglePoint"
                />
                <div className="flex flex-col ml-4">
                  <div className={styles.label}>E-Signing</div>
                  <div className={`${styles.text} mt-2`}>
                    Execute legally binding digital agreements with multiple
                    parties in few clicks and manage the executed digital copies
                    on our Agreements Manager with ease and efficiency
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-start">
                <img
                  src={logo}
                  width="24px"
                  alt="rectanglePoint"
                />
                <div className="flex flex-col ml-4">
                  <div className={styles.label}>E-Stamping</div>
                  <div className={`${styles.text} mt-2`}>
                    Procure and affix e-stamp papers to legal documents easily
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-start">
                <div>
                  <ReportMoney />
                </div>
                <div className="flex flex-col ml-4">
                  <div className={styles.label}>Budget Manager</div>
                  <div className={`${styles.text} mt-2`}>
                    Manage your expenses and recoveries to effectively increase
                    the efficiency of your legal operations using our Budget
                    Manager
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-start">
                <div>
                  <Users />
                </div>
                <div className="flex flex-col ml-4">
                  <div className={styles.label}>Network Manager</div>
                  <div className={`${styles.text} mt-2`}>
                    Manage your network of legal professionals or use our
                    network for your legal operations easily and efficiently
                    using our Network Manager module
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="-mr-1">
            <img
              src={logo2}
              alt="agreement-module-logo"
            />
          </div>
        </div>
      </div>
          </div>
  );
}

export default Section3;
