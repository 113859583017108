/** @format */

import { copyToClipboard } from "../../utilities/utilities";

import React from "react";
import { HiOutlineClipboardCopy } from "react-icons/hi";

function CopyComponent(props) {
  return (
    <HiOutlineClipboardCopy
      size={20}
      onClick={() => copyToClipboard(props.text)}
    />
  );
}

export default CopyComponent;
