import axios from 'axios';
import { headersProvider } from '../apiHelpers';
import constants from '../constants';

export const createDocument = ({
    agreementId
}) => axios.post(`${constants.utilityHost}/docs/create`, {
    agreementId
},
    {
        headers: headersProvider(),
    });

export const getLink = ({
    agreementId
}) => axios.get(`${constants.utilityHost}/docs/getLink/${agreementId}`,
    {
        headers: headersProvider(),
    });

export const shareLink = ({
    agreementId
}) => axios.get(`${constants.utilityHost}/docs/shareLink/${agreementId}`,
    {
        headers: headersProvider(),
    });

export const grantPermission = ({
    role, type, emailAddress, documentId, documetEditorId
}) => axios.post(`${constants.utilityHost}/docs/permission/${documetEditorId}`, {
    role, type, emailAddress, documentId
},
    {
        headers: headersProvider(),
    });
export const fetchDocsPermission = (
    documentEditorId
) => axios.get(`${constants.utilityHost}/docs/fetchPermission/${documentEditorId}`,
    {
        headers: headersProvider(),
});
export const fetchDocsfetchRevisions = (
    documentEditorId
) => axios.get(`${constants.utilityHost}/docs/fetchRevisions/${documentEditorId}`,
    {
        headers: headersProvider(),
});
export const fetchDocsContent = (
    documentEditorId
)=> axios.get(`${constants.utilityHost}/docs/fetchDocsContent/${documentEditorId}`,
    {
        headers: headersProvider(),
});
export const deleteDocsPermission = (
    documentEditorId
)=> axios.get(`${constants.utilityHost}/docs/deleteDocsPermission/${documentEditorId}`,
    {
        headers: headersProvider(),
});
export const GET_DownloadDocsPdfLink = ({
    agreementId
}) => axios.get(`${constants.utilityHost}/docs/downloadDocsPdfLink/${agreementId}`,
    {
        headers: headersProvider(),
    });
export const fetchDocsContentBase64 = (
    agreementId
)=> axios.get(`${constants.utilityHost}/docs/fetchDocsContentBase64/${agreementId}`,
    {
        headers: headersProvider(),
});