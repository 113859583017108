import { Button } from '@mantine/core';
import React, { useState } from 'react';
import { apiWrapWithErrorWithData } from '../../utilities/apiHelpers';
import { updateAgreement } from '../../utilities/apis/agreements';
import { showNotification } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';


const EditSignatoryDetails = ({
    setShowEditSignatoryModal, agreementData }
) => {
    console.log("agreementData for modal", agreementData);
    const [input1, setInput1] = useState(agreementData.wholeAgreement.firstParty.signatory ? agreementData.wholeAgreement.firstParty.signatory: null);
    const [input2, setInput2] = useState(agreementData.wholeAgreement.secondParty.signatory ? agreementData.wholeAgreement.secondParty.signatory : null);
    const navigate = useNavigate();
    const agreementArgs = {
        typeOfAgreement: agreementData.wholeAgreement.typeOfAgreement,
        titleOfAgreement: agreementData.wholeAgreement.titleOfAgreement,
        requestingUserDetails: agreementData.wholeAgreement.requestingUser,
        dateOfContract: agreementData.wholeAgreement.dateOfContract,
        contractTermInDays: agreementData.wholeAgreement.contractTermInDays,
        noOfYears: agreementData.wholeAgreement.noOfYears,
        noOfMonths: agreementData.wholeAgreement.noOfMonths,
        location: agreementData.wholeAgreement.location,
        reminderTimeInDays: agreementData.wholeAgreement.reminderTimeInDays,
        dueDateOfContract: agreementData.wholeAgreement.dueDateOfContract,
        reminderTime: agreementData.wholeAgreement.reminderTime,
        contractQuillJsDelta: agreementData.wholeAgreement.contractQuillJsDelta,
        firstParty: {
            ...agreementData.wholeAgreement.firstParty,
            ...(agreementData.wholeAgreement.firstParty.signatory && { signatory: input1 })
        },
        secondParty: {
            ...agreementData.wholeAgreement.secondParty,
            ...(agreementData.wholeAgreement.secondParty.signatory && { signatory: input2 })
        },
        thirdParty: agreementData.wholeAgreement.thirdParty,
        fourthParty: agreementData.wholeAgreement.fourthParty,
        fifthParty: agreementData.wholeAgreement.fifthParty,
        formCompleted: !!agreementData.wholeAgreement.formCompleted,
        relationshipType: agreementData.wholeAgreement.relationshipType,
        relationshipWith: agreementData.wholeAgreement.relationshipWith,
        businessUnit: agreementData.wholeAgreement.businessUnit || "1",
    };
    const args = {
        agreementId: agreementData.agreementId,
        agreementData: {
            json: {
                ...agreementData.data.json,
                firstParty: {
                    ...agreementData.data.json.firstParty,
                    ...(agreementData.data.json.firstParty.signatory && { signatory: input1 })
                },
                secondParty: {
                    ...agreementData.data.json.secondParty,
                    ...(agreementData.data.json.secondParty.signatory && { signatory: input2 })
                },
            },
            flatted: agreementData.data.flatted,
        },
        ...agreementArgs
    }
    const updateAgreementSignatory = async (e) => {
        e.preventDefault();
        const response = await apiWrapWithErrorWithData(updateAgreement(args));
        if(response.success){
            showNotification({
                color: 'green',
                title: 'Signatory Details Updated',
                message: 'Signatory Details has been Updated.',
            });
            setShowEditSignatoryModal(false)
            setTimeout(() => {
                navigate(`/app/agreements_manager/agreements/details/${agreementData.agreementId}`);
                window.location.reload();
            }, 9000);
        } else {
            showNotification({
                color: 'red',
                title: 'Signatory Details Update',
                message: 'Signatory Details not Updated.',
            }); 
            setShowEditSignatoryModal(false)
        }
    }
    console.log("agreementData for modal", agreementData);
    return (
        <div className='mb-4'>

            <div className='mb-4'>
                <h3 className='text-yellow-400 mb-2'>Note: You can only edit signatory names.</h3>
                {agreementData.wholeAgreement.firstParty?.signatory && (<>
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="username" >
                        First Party Signatory Name :
                    </label>
                    <input type="text" value={input1} onChange={(e) => setInput1(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                </>)}
                {agreementData.wholeAgreement.secondParty?.signatory && (<>
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="username">
                        Second Party Signatory Name :
                    </label>
                    <input type="text" value={input2} onChange={(e) => setInput2(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                </>)}
                {!agreementData.wholeAgreement.firstParty?.signatory && agreementData.wholeAgreement.secondParty?.signatory && (<h2>You have no signatory details to update</h2>)}

            </div>
            <Button className='bg-white hover:bg-white text-black border border-gray-400 rounded shadow' onClick={() => setShowEditSignatoryModal(false)}>Cancel</Button>
            {agreementData.wholeAgreement.secondParty?.signatory && agreementData.wholeAgreement.firstParty?.signatory && ( <Button onClick={(e)=> updateAgreementSignatory(e)}>Submit</Button>)}
        </div>
    );
}

export default EditSignatoryDetails;
