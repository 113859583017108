/** @format */
import { memo } from "react";
import {
  Text,
  SegmentedControl,
  Select,
  TextInput,
  Textarea,
} from "@mantine/core";

function AgreementBulkParty({
  setDropDownNames,
  dropDownNames,
  setPartyData,
  partyData,
}) {
  return (
    <div className="flex flex-col">
      <div className="flex justify-between">
        <Text color="#46BDE1">{"First party"}</Text>
        <SegmentedControl
          color="cyan"
          value={partyData.type}
          data={["Individual", "Entity"]}
          onChange={(value) => setPartyData((pre) => ({ ...pre, type: value }))}
        />
      </div>
      <div className="grid grid-cols-3 gap-4">
        <Select
          required
          searchable
          creatable
          className="inputCustom mx-4"
          label="Name"
          placeholder="Enter Name"
          value={partyData.name}
          data={[...dropDownNames]}
          getCreateLabel={(query) => `+ Add ${query}`}
          onChange={(value) => setPartyData((pre) => ({ ...pre, name: value }))}
          onCreate={(query) => {
            setDropDownNames([...dropDownNames, query]);
          }}
          style={{ width: "305px", marginLeft: "0px" }}
        />
        <TextInput
          required
          label="Authorised Signatory Email ID"
          placeholder="Enter Authorised Signatory Email ID"
          value={partyData.value}
          onChange={(value) =>
            setPartyData((pre) => ({ ...pre, email: value }))
          }
        />
        {partyData.type === "Individual" ? (
          <TextInput
            label="Business POC"
            placeholder="Enter Business POC"
            value={partyData.representative}
            onChange={(value) =>
              setPartyData((pre) => ({ ...pre, representative: value }))
            }
          />
        ) : (
          <Select
            className="inputCustom mx-4"
            label="Entity Type"
            value={partyData.companyType}
            data={[
              { value: "Company", label: "Company" },
              { value: "LLP", label: "LLP" },
              { value: "Partnership Firm", label: "Partnership Firm" },
              { value: "Sole Proprietary", label: "Sole Proprietary" },
            ]}
            onChange={(value) =>
              setPartyData((pre) => ({ ...pre, companyType: value }))
            }
          />
        )}
        {partyData.type === "Individual" ? (
          <TextInput
            label="Authorised Signatory"
            placeholder="Enter Signatory"
            value={partyData.signatory}
            onChange={(value) =>
              setPartyData((pre) => ({ ...pre, signatory: value }))
            }
          />
        ) : (
          <Textarea
            label="Corporate Address"
            placeholder="Enter Corporate Address"
            value={partyData.corporateOfficeAddress}
            onChange={(value) =>
              setPartyData((pre) => ({ ...pre, corporateOfficeAddress: value }))
            }
          />
        )}
        <Textarea
          label="Registered Address"
          placeholder="Enter Registered Address"
          value={partyData.address}
          onChange={(value) =>
            setPartyData((pre) => ({ ...pre, address: value }))
          }
        />
      </div>
    </div>
  );
}

export default memo(AgreementBulkParty);
