import axios from "axios";
import { headersProvider } from "../apiHelpers";
import constants from "../constants";

export const getPartiesList = ({
  name,
}) => axios.post(`${constants.utilityHost}/parties/list`, {
  name,
},
{
  headers: headersProvider(),
});

// export const getWorkSpaceLists = () =>
//   axios.post(`${constants.utilityHost}/parties/list`, 
//   {
//     headers: headersProvider(),
//   });
