/* eslint-disable */
import React, { useState } from "react";
import { Avatar, Menu, Text, Input, CloseButton, Select } from "@mantine/core";
import { useSelector, useDispatch } from "react-redux";
import { AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import styles from "./AppHeader.module.css";
import { assetsPrefix } from "../../utilities/utilities";
import { selectUserData } from "../../redux/selectors";
import { DoorExit, Lock } from "tabler-icons-react";
import { deleteCookie, getCookiesSession } from "../../cookiesSession";
import axios from "axios";
import { changeUserRole } from "../../utilities/apis/users";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { setCookiesSession } from "../../cookiesSession";
import actions from "../../redux/actions";

const AppHeader = () => {
  const userData = useSelector(selectUserData);
  const [opened, setOpened] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectUserData) || {};
  const roles = currentUser.userRole || [];
  // console.log("roles", roles);

  const logoutHandler = () => {
    if (getCookiesSession("trueCounselUserData")) {
      deleteCookie("trueCounselUserData");
    }
    window.location = process.env.REACT_APP_LANDING_PAGE_URL;
  };
  const handleRoleChange = async (value) => {
    try {
      const resp = await apiWrapWithErrorWithData(changeUserRole(value));
      // console.log(resp);
      if (resp?.success && resp?.token && resp?.user) {
        // console.log("dispatch");
        //  setConfigs((prevState) => ({
        //    ...prevState,
        //    loading: loadingStates.NO_ACTIVE_REQUEST,
        //  }));
        setCookiesSession("trueCounselUserData", JSON.stringify(resp), 2);
        dispatch({
          type: actions.SET_USER_DATA,
          payload: resp.user,
        });
        // navigate("/app/");
        // setTimeout(() => {
        //   navigate("/app/agreements_manager");
        // }, 500);
        navigate(0)
      }
      // Reload the page
    } catch (error) {
      console.error("Error while switching roles:", error);
      // Handle error if needed
    }
  };
  return (
    <div className="flex flex-row justify-between mx-8 my-2">
      <img
        className={styles.logo}
        src={`${assetsPrefix}/images/trueCounselLogo.png`}
        alt="TrueCounsel"
      />
      <div className="flex">
        {/* <Select
          required
          className="max-w-lg"
          label="Select Role"
          placeholder="Select Role to login as"
        
        /> */}

        {/* <Input
          icon={<AiOutlineSearch />}
          value={searchInput}
          rightSection={
            <CloseButton
              onClick={() => {
                setSearchInput("");
                navigate("/app");
              }}
            />
          }
          placeholder="Search"
          className="mx-3"
          onChange={(e) => {
            const input = e.target.value;
            input.length > 2
              ? navigate(`/app/search/${input}`)
              : navigate("/app");
            setSearchInput(input);
          }}
        /> */}
        <Select
          required
          className="max-w-lg mr-2"
          placeholder="Select Role to login as"
          data={roles.map((role) => ({
            label: role.name,
            value: role.id,
            pass: role,
          }))}
          // label={currentUser.loginAs.name}
          value={currentUser.loginAs.id}
          onChange={(pass) => handleRoleChange(pass)}
        />
        <Menu>
          <Menu.Target>
            <div
              onClick={() => {
                setOpened(!opened);
              }}
              className="flex items-center cursor-pointer"
            >
              {userData.picture?.length ? (
                <Avatar size="md" src={userData.picture} radius="xl" />
              ) : (
                <Avatar size="md" radius="xl">
                  {userData.name[0].toUpperCase()}
                </Avatar>
              )}
              <div className="ml-2 flex flex-col">
                <Text size="md">{userData.name}</Text>
                <Text size="xs">{userData.email}</Text>
              </div>
            </div>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item onClick={()=>navigate('/app/updatepassword')} icon={<Lock size={14} />}>
              update password
            </Menu.Item>
            <Menu.Item onClick={logoutHandler} icon={<DoorExit size={14} />}>
              Logout
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </div>
    </div>
  );
};

export default AppHeader;
