import { React, useEffect, useState } from "react";
import { useDebouncedValue } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import {
  ActionIcon,
  Anchor,
  Modal,
  Badge,
  Pagination,
  Select,
  Skeleton,
  Table,
  TextInput,
  Button,
  Textarea,
} from "@mantine/core";
import { Edit, Eye, Search, SortAscending } from "tabler-icons-react";
import { useNavigate } from "react-router-dom";
import { listServiceProviders } from "../../utilities/apis/serviceProvider";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { getValueForInput, loadingStates } from "../../utilities/utilities";
import ServiceProviderForm from "../../components/ServiceProviderForm/ServiceProviderForm";
import ServiceProviderAnalytics from "./ServiceProviderAnalytics";
import { selectUserData } from "../../redux/selectors";
import { useSelector } from "react-redux";
import {
  getOrgDetails,
  orgDetailsUpdate,
} from "../../utilities/apis/agreements";
import UpdateUser from "../../components/ServiceProviderForm/updateSeviceProviderForm";

const ServiceProviderList = () => {
  const [configs, setConfigs] = useState({
    list: null,
    serviceProvidersCount: null,
    loading: loadingStates.NO_ACTIVE_REQUEST,
    page: 1,
    sortBy: "createdAt",
    sortDirection: "desc",
    searchString: "",
    formOpen: false,
  });
  const [editModal, setEditModal] = useState(false);
  const currentUser = useSelector(selectUserData) || {};
  const [orgDetailsModal, setOrgDetailsModal] = useState(false);
  const [debouncedSearchInput] = useDebouncedValue(configs.searchString, 500);

  const fetchServiceProviders = async () => {
    if (configs.loading === loadingStates.LOADING) {
      return;
    }
    setConfigs((prevState) => ({
      ...prevState,
      loading: loadingStates.LOADING,
    }));
    const resp = await apiWrapWithErrorWithData(
      listServiceProviders({
        page: configs.page,
        sortByOptions: {
          [configs.sortBy]: configs.sortDirection,
        },
        filterOptions: debouncedSearchInput?.length
          ? {
              OR: [
                {
                  email: {
                    contains: debouncedSearchInput,
                  },
                },
                {
                  name: {
                    contains: debouncedSearchInput,
                  },
                },
                {
                  designation: {
                    contains: debouncedSearchInput,
                  },
                },
                {
                  phone: {
                    contains: debouncedSearchInput,
                  },
                },
              ],
            }
          : null,
      })
    );
    if (resp?.success && resp.serviceProviders) {
      setConfigs((prevState) => ({
        ...prevState,
        loading: loadingStates.NO_ACTIVE_REQUEST,
        list: resp.serviceProviders,
        serviceProvidersCount: resp.serviceProvidersCount,
      }));
    } else {
      setConfigs((prevState) => ({
        ...prevState,
        loading: loadingStates.NO_ACTIVE_REQUEST,
        list: null,
      }));
      // showNotification({
      //   title: 'Service Providers',
      //   message: 'Cannot load service providers list.',
      //   color: 'red',
      // });
    }
  };

  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState(0);
  const [selectedUserData, setSelectedUserData] = useState({});
  useEffect(() => {
    fetchServiceProviders();
  }, [
    configs.page,
    configs.sortBy,
    configs.sortDirection,
    debouncedSearchInput,
  ]);

  const sortOptions = [
    {
      label: "Created",
      value: "createdAt",
    },
    {
      label: "Name",
      value: "name",
    },
    {
      label: "Designation",
      value: "designation",
    },
  ];
  const [orgDetails, setOrgDetails] = useState({
    entityType: "Company",
    regAddress: "",
  });
  const [orgName, setOrgName] = useState("");
  useEffect(() => {
    const getOrganizationDetails = async () => {
      const resp = await apiWrapWithErrorWithData(getOrgDetails());
      if (resp.success) {
        setOrgName(resp.organizationDetails.orgName);
        setOrgDetails({
          entityType: resp.organizationDetails.orgData.entityType,
          regAddress: resp.organizationDetails.orgData.regAddress,
        });
      }
    };
    getOrganizationDetails();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await apiWrapWithErrorWithData(
        orgDetailsUpdate({
          orgName: orgName,
          orgDetails: orgDetails,
        })
      );
      showNotification({
        color: "green",
        message: "Organization Details Updated",
        title: "Update Organization Details",
      });
      setOrgDetailsModal(false);
    } catch (error) {
      showNotification({
        color: "red",
        message: "Organization Details Not Updated",
        title: "Couldn't Update Organization Details",
      });
    }
  };

  // console.log(configs.list);

  return (
    <div className="flex flex-col">
      {/* <ServiceProviderAnalytics /> */}
      <Modal
        opened={orgDetailsModal}
        onClose={() => {
          setOrgDetailsModal(false);
        }}
        size="calc(80vw)"
      >
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="grid grid-cols-2 gap-4  mt-8">
            <Select
              required
              label="Entity Type"
              data={[
                { value: "Company", label: "Company" },
                { value: "LLP", label: "LLP" },
                { value: "Partnership Firm", label: "Partnership Firm" },
                { value: "Sole Proprietary", label: "Sole Proprietary" },
              ]}
              value={orgDetails.entityType}
              onChange={(value) => {
                setOrgDetails({
                  ...orgDetails,
                  entityType: value,
                });
              }}
            />
            <TextInput
              required
              placeholder="Company Registered Name"
              label="Company Registered Name"
              value={orgName}
              onChange={(e) => {
                setOrgName(e.target.value);
              }}
            />
            <Textarea
              required
              placeholder="Registered Address"
              label="Registered Address"
              rows={2}
              value={orgDetails.regAddress}
              onChange={(e) => {
                setOrgDetails({
                  ...orgDetails,
                  regAddress: e.target.value,
                });
              }}
            />
          </div>
          <div className="flex justify-end">
            <Button color="blue" type="submit">
              Save
            </Button>
          </div>
        </form>
      </Modal>
      <Modal
        opened={editModal}
        onClose={() => {
          setEditModal(false);
          setSelectedUser(0);
          setSelectedUserData({});
        }}
        size="calc(80vw)"
      >
        <UpdateUser
          selectUser={selectedUser}
          selectUserData={selectedUserData}
          setEditModal={setEditModal}
          setSelectedUser={setSelectedUser}
          setSelectedUserData={setSelectedUserData}
        />
      </Modal>
      <Modal
        opened={configs.formOpen}
        onClose={() => {
          setConfigs((prevState) => ({
            ...prevState,
            formOpen: false,
          }));
          fetchServiceProviders();
        }}
        size="calc(80vw)"
      >
        <ServiceProviderForm
          formClose={() => {
            setConfigs((prevState) => ({
              ...prevState,
              formOpen: false,
            }));
            fetchServiceProviders();
          }}
          selectedUser={selectedUser}
        />
      </Modal>
      <div className="flex justify-between my-2">
        <div>
          <Button
            onClick={() => {
              setOrgDetailsModal(true);
            }}
            style={{
              backgroundColor: "#244D61",
            }}
          >
            organisation details
          </Button>
        </div>
        <div>
          {currentUser.loginAs.id === 1 && (
            <Button
              onClick={() => {
                navigate("add-businessunit");
              }}
              style={{
                backgroundColor: "#5689C0",
                marginRight: "5px",
              }}
            >
              add business unit/region
            </Button>
          )}

          <Button
            onClick={() => {
              setConfigs((prevState) => ({
                ...prevState,
                formOpen: true,
              }));
            }}
            style={{
              backgroundColor: "#75E2FF",
            }}
          >
            add user
          </Button>
        </div>
      </div>
      <div className="flex w-full justify-between items-center">
        <div className="flex flex-col">
          <div className="flex items-center">
            <TextInput
              disabled={configs.loading === loadingStates.LOADING}
              value={configs.searchString}
              onChange={(input) => {
                const val = getValueForInput(input);
                setConfigs((prevState) => ({
                  ...prevState,
                  searchString: val,
                }));
              }}
              placeholder="type to search..."
              icon={<Search size={14} />}
            />
            <Anchor
              className="ml-2"
              onClick={() => {
                setConfigs((prevState) => ({
                  ...prevState,
                  searchString: "",
                }));
              }}
            >
              clear
            </Anchor>
          </div>
        </div>
        <div className="flex items-center">
          sort:
          <Select
            disabled={configs.loading === loadingStates.LOADING}
            className="mx-2"
            value={configs.sortBy}
            onChange={(val) => {
              setConfigs((prevState) => ({
                ...prevState,
                sortBy: val,
              }));
            }}
            data={sortOptions}
          />
          <ActionIcon
            onClick={() => {
              setConfigs((prevState) => ({
                ...prevState,
                sortDirection:
                  prevState.sortDirection === "asc" ? "desc" : "asc",
              }));
            }}
            color="blue"
            className={configs.sortDirection === "asc" ? "" : "rotate-180"}
          >
            <SortAscending />
          </ActionIcon>
        </div>
      </div>
      {configs.loading === loadingStates.LOADING && (
        <div className="flex flex-col">
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <div className="flex flex-row justify-center">
            <Skeleton height={40} radius="md" className="w-40" />
          </div>
        </div>
      )}
      {configs.loading !== loadingStates.LOADING && !!configs.list && (
        <div className="flex flex-col">
          <Table striped>
            <thead>
              <tr>
                <th>reference No.</th>
                <th>name</th>
                {/* <th>Designation</th> */}
                <th>roles</th>
                <th>business unit/region</th>
                <th>email</th>

                <th>enabled</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {configs.list.map((row) => (
                <tr key={row.id}>
                  <td>{`user - ${row.id}`}</td>
                  <td>{`${row.name} `}</td>
                  {/* <td>{`${row.designation || "-"}`}</td> */}
                  <td>{`${row.userRole || "-"}`}</td>
                  <td>{`${row.userBusinessunit || "-"}`}</td>
                  <td>{`${row.email}`}</td>

                  <td>
                    <Badge color={row.enabled ? "green" : "red"}>
                      {row.enabled ? "YES" : "NO"}
                    </Badge>
                  </td>
                  <td>
                    <div className="flex flex-row">
                      {/* <ActionIcon
                        onClick={() => {
                          navigate(`/app/service-providers/details/${row.id}`);
                        }}
                        color="white"
                      >
                        <Eye size={24} />
                      </ActionIcon> */}
                      <ActionIcon
                        onClick={() => {
                          setSelectedUser(parseInt(row.id));
                          setSelectedUserData(row);
                          setEditModal(true);
                        }}
                        color="white"
                      >
                        <Edit size={24} />
                      </ActionIcon>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {configs.serviceProvidersCount === 0 && (
            <div className="flex justify-center items-center mt-4">
              <div>no Users</div>
            </div>
          )}
          {!!configs.serviceProvidersCount && (
            <div className="flex flex-row justify-center my-4">
              <Pagination
                page={configs.page}
                onChange={(page) => {
                  setConfigs({
                    ...configs,
                    page,
                  });
                }}
                total={Math.ceil(configs.serviceProvidersCount / 10)}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ServiceProviderList;
