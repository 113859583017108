import { Button } from "@mantine/core";
import React, { useState } from "react";
import styles from "./Section2.module.scss";
import { useNavigate } from "react-router-dom";

function Section2() {
  const [state, setState] = useState(false);
   const navigate = useNavigate();

   const handleAgreementsManagerClick = () => {
    // console.log('press');
     navigate("/app/agreements_manager");
   };

  //  const handleAgreementsManagerClick2 = () => {
  //   console.log('press');
  //    navigate("/app/agreements_manager/notifications");
  //  };

   const handleNotificationsClick = () => {
     navigate("/app/agreements_manager/notifications");
   };

  return (
    <div>
      <div>
        <div className={`mb-10 ${styles.sectionHeading}`}>
          Manage your agreements and disputes with ease
        </div>
        <div
          className={`mt-3 flex flex-row justify-center items-center ${styles.buttonContainer}`}
        >
          <div
            style={{
              // border: "2px solid red",
              position: "relative",
            }}
          >
            <Button
              className={`${styles.buttonCommon}`}
              variant="outline"
              onClick={handleAgreementsManagerClick}
              
              style={
                !state
                  ? { background: "#46BDE1", color: "white" }
                  : { background: "white", color: "#46BDE1" }
              }
            >
              Agreements Manager
            </Button>
            {/* <div
              style={{
                // border: "2px solid green",
                width: "min-content",
                position: "absolute",
                top: "-10px",
                right: "-3px",
                cursor: "pointer",
              }}
              onClick={handleNotificationsClick}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="34"
                  height="34"
                  viewBox="0 0 24 24"
                  fill="#111"
                  class="icon icon-tabler icons-tabler-filled icon-tabler-bell-ringing"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M17.451 2.344a1 1 0 0 1 1.41 -.099a12.05 12.05 0 0 1 3.048 4.064a1 1 0 1 1 -1.818 .836a10.05 10.05 0 0 0 -2.54 -3.39a1 1 0 0 1 -.1 -1.41z" />
                  <path d="M5.136 2.245a1 1 0 0 1 1.312 1.51a10.05 10.05 0 0 0 -2.54 3.39a1 1 0 1 1 -1.817 -.835a12.05 12.05 0 0 1 3.045 -4.065z" />
                  <path d="M14.235 19c.865 0 1.322 1.024 .745 1.668a3.992 3.992 0 0 1 -2.98 1.332a3.992 3.992 0 0 1 -2.98 -1.332c-.552 -.616 -.158 -1.579 .634 -1.661l.11 -.006h4.471z" />
                  <path d="M12 2c1.358 0 2.506 .903 2.875 2.141l.046 .171l.008 .043a8.013 8.013 0 0 1 4.024 6.069l.028 .287l.019 .289v2.931l.021 .136a3 3 0 0 0 1.143 1.847l.167 .117l.162 .099c.86 .487 .56 1.766 -.377 1.864l-.116 .006h-16c-1.028 0 -1.387 -1.364 -.493 -1.87a3 3 0 0 0 1.472 -2.063l.021 -.143l.001 -2.97a8 8 0 0 1 3.821 -6.454l.248 -.146l.01 -.043a3.003 3.003 0 0 1 2.562 -2.29l.182 -.017l.176 -.004z" />
                </svg>
              </span>
            </div> */}
          </div>
          <Button
            className={`${styles.buttonCommon} `}
            variant="outline"
            style={{
              background: "white",
              color: "#46BDE1",
              border: "2px solid #46BDE1",
              padding: "0",
            }}
            disabled
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "block", textAlign: "center" }}>
                Disputes Manager
              </div>
              <div
                style={{
                  display: "block",
                  fontSize: "15px",
                  textAlign: "center",
                  fontFamily: "sans-serif"
                }}
              >
                Coming Soon
              </div>
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
}
export default Section2;
