import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Modal,
  Select,
  Skeleton,
  Text,
  TextInput,
} from "@mantine/core";
import { Search } from "tabler-icons-react";
import flat from "flat";
import { showNotification } from "@mantine/notifications";
import { BeatLoader } from "react-spinners";
import colors from "../../utilities/design";
import AgreementListModal from "./AgreementListModal";
import { DatePicker } from "@mantine/dates";
import { getBussinessUnit } from "../../utilities/apis/serviceProvider";
import { formatISO, parseISO } from "date-fns";
import { loadingStates } from "../../utilities/utilities";

export const INDIA_STATES_UNIONS = [
  { label: "Andhra Pradesh", value: "Andhra Pradesh" },
  { label: "Karnataka", value: "Karnataka" },
  {
    label: "Andaman and Nicobar Islands",
    value: "Andaman and Nicobar Islands",
  },
  { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
  { label: "Assam", value: "Assam" },
  { label: "Bihar", value: "Bihar" },
  { label: "Chandigarh", value: "Chandigarh" },
  { label: "Chattisgarh", value: "Chattisgarh" },
  { label: "Dadra and Nagar Haveli", value: "Dadra and Nagar Haveli" },
  { label: "Daman and Diu", value: "Daman and Diu" },
  { label: "Delhi", value: "Delhi" },
  { label: "Goa", value: "Goa" },
  { label: "Gujarat", value: "Gujarat" },
  { label: "Haryana", value: "Haryana" },
  { label: "Himachal Pradesh", value: "Himachal Pradesh" },
  { label: "Jammu and Kashmir", value: "Jammu and Kashmir" },
  { label: "Jharkhand", value: "Jharkhand" },
  { label: "Kerala", value: "Kerala" },
  { label: "Lakshadweep Islands", value: "Lakshadweep Islands" },
  { label: "Madhya Pradesh", value: "Madhya Pradesh" },
  { label: "Maharashtra", value: "Maharashtra" },
  { label: "Manipur", value: "Manipur" },
  { label: "Meghalaya", value: "Meghalaya" },
  { label: "Mizoram", value: "Mizoram" },
  { label: "Nagaland", value: "Nagaland" },
  { label: "Odisha", value: "Odisha" },
  { label: "Pondicherry", value: "Pondicherry" },
  { label: "Punjab", value: "Punjab" },
  { label: "Rajasthan", value: "Rajasthan" },
  { label: "Sikkim", value: "Sikkim" },
  { label: "Tamil Nadu", value: "Tamil Nadu" },
  { label: "Telangana", value: "Telangana" },
  { label: "Tripura", value: "Tripura" },
  { label: "Uttar Pradesh", value: "Uttar Pradesh" },
  { label: "Uttarakhand", value: "Uttarakhand" },
  { label: "West Bengal", value: "West Bengal" },
];

const LegacyAgreementForm1 = ({
  setLegacyAgreementFormValue,
  legacyAgreementFormValue,
  uiConfigs,
  setUiConfigs,
  saveAgreement,
}) => {
  const typeOfDocuments = [
    "Parent",
    "Supplementary",
    "Supplementary to Supplementary",
  ];
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLegacyAgreementFormValue({
      ...legacyAgreementFormValue,
      requestingUser: {
        ...legacyAgreementFormValue.requestingUser,
        [name]: value,
      },
    });
  };

  const [businessUnitsOptions, setBusinessUnitsOptions] = useState([]);
  useEffect(() => {
    const fetchBusinessUnits = async () => {
      try {
        const { data } = await getBussinessUnit();

        if (data) {
          setBusinessUnitsOptions(
            data.businessunitList.map((unit) => ({
              value: unit.id + "",
              label: unit.name,
            })) || []
          );
        } else {
          console.error("Invalid business unit response format:", data);
        }
      } catch (error) {
        console.error("Error fetching business units:", error);
      }
    };
    fetchBusinessUnits();
  }, []);
  const reminderLabels = {
    15: "15 days",
    30: "30 days",
    45: "45 days",
    60: "60 days",
  };
  const saveNext = async () => {
    const keys = {};
    if (
      !legacyAgreementFormValue?.relationshipType ||
      legacyAgreementFormValue?.relationshipType?.length < 3
    ) {
      keys.typeOfDocument = "Type of Document is mandatory.";
    }

    if (
      !legacyAgreementFormValue?.typeOfAgreement ||
      legacyAgreementFormValue?.typeOfAgreement?.length < 3
    ) {
      keys.typeOfAgreement = "Type is mandatory.";
    }
    if (
      !legacyAgreementFormValue?.titleOfAgreement ||
      legacyAgreementFormValue?.titleOfAgreement?.length < 3
    ) {
      keys.titleOfAgreement = "Title is mandatory.";
    }
    if (
      !legacyAgreementFormValue?.titleOfAgreement ||
      legacyAgreementFormValue?.titleOfAgreement?.length > 50
    ) {
      keys.titleOfAgreement =
        "The title for the agreement should stay within 50 characters or less.";
    }
    if (
      !legacyAgreementFormValue.dateOfContract ||
      isNaN(parseISO(legacyAgreementFormValue.dateOfContract).getTime())
    ) {
      keys["dateOfContract"] = "Please enter date.";
    }

    if (!legacyAgreementFormValue.location) {
      keys["location"] = "Please enter location.";
    }
    if (!legacyAgreementFormValue.businessUnit) {
      keys["businessUnit"] = "Please enter Business Unit.";
    }

    if (
      (!legacyAgreementFormValue.noOfYears ||
        isNaN(legacyAgreementFormValue.noOfYears) ||
        parseInt(legacyAgreementFormValue.noOfYears, 10) <= 0) &&
      (!legacyAgreementFormValue.noOfMonths ||
        isNaN(legacyAgreementFormValue.noOfMonths) ||
        parseInt(legacyAgreementFormValue.noOfMonths, 10) <= 0)
    ) {
      keys["noOfYears"] = "Please check value.";
      keys["noOfMonths"] = "Please check value.";
    }
    // if (legacyAgreementFormValue?.requestingUser?.phone?.length > 0) {
    //   if (
    //     !validateMobile(legacyAgreementFormValue?.requestingUser?.phone) ||
    //     legacyAgreementFormValue?.requestingUser?.phone.length !== 10
    //   ) {
    //     keys["requestingUser.phone"] = "Please enter 10 digits phone number.";
    //   }
    // } else {
    //   keys["requestingUser.phone"] = "Please enter 10 digits phone number.";
    // }
    console.log("Validation errors:", keys);
    if (Object.keys(keys).length > 0) {
      form.setErrors(keys);
      showNotification({
        color: "red",
        title: "Agreement Form",
        message: "Please check all fields are filled properly.",
      });
    } else {
      await saveAgreement();
      setUiConfigs({
        ...uiConfigs,
        currentStep: 1,
      });
    }
  };
  const [showModal, setShowModal] = useState(false);
  const [selectedAgreement, setSelectedAgreement] = useState(0);
  const handleSearchClick = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (selectedAgreement) {
      setLegacyAgreementFormValue({
        ...legacyAgreementFormValue,
        relationshipWith: selectedAgreement + "",
      });
    }
  }, [selectedAgreement]);

  console.log("agreementFormValues", legacyAgreementFormValue);
  return (
    <>
      {showModal && (
        <Modal
          overflow="inside"
          opened
          onClose={handleCloseModal} // Call the handler on modal close
          size="calc(80vw)"
        >
          <AgreementListModal
            onCloseAgreement={handleCloseModal}
            setSelectedAgreement={setSelectedAgreement}
          />
        </Modal>
      )}
      <div className="w-full flex flex-col my-4">
        <div className="grid grid-cols-2">
          <div>
            <Select
              required
              className="max-w-lg"
              label="Type of Document"
              placeholder="Select type of Document"
              data={typeOfDocuments.map((value) => ({ value, label: value }))}
              onChange={(selectedOption) => {
                setLegacyAgreementFormValue({
                  ...legacyAgreementFormValue,
                  relationshipType: selectedOption,
                });
              }}
              value={legacyAgreementFormValue.relationshipType}
              style={{ maxWidth: "82%" }}
            />
          </div>
          <div style={{ maxWidth: "82%" }} className="flex">
            <TextInput
              label="Connected Agreement"
              placeholder="Enter Agreement Number"
              required={
                legacyAgreementFormValue.relationshipType === "Supplementary" ||
                legacyAgreementFormValue.relationshipType ===
                  "Supplementary to Supplementary"
              }
              onChange={(e) => {
                setLegacyAgreementFormValue({
                  ...legacyAgreementFormValue,
                  relationshipWith: e.target.value,
                });
              }}
              value={legacyAgreementFormValue.relationshipWith}
              type="number"
              style={{
                width: "88%",
                display: "inline-block",
                marginRight: "2%",
              }}
            />
            <Button
              className="max-w-lg"
              color="#2196F3"
              onClick={handleSearchClick} // Call the handler on button click
              style={{
                width: "10%",
                display: "inline-block",
                marginBottom: "0",
                marginTop: "auto",
                padding: "0",
              }}
            >
              <Search size={20} strokeWidth={3} />
            </Button>
          </div>
        </div>
        <div className="grid grid-cols-2 mt-4">
          <Select
            searchable
            required
            creatable
            className="max-w-lg"
            style={{ maxWidth: "82%" }}
            label="Agreement Category"
            placeholder="Select Agreement Category"
            data={legacyAgreementFormValue.typeOfAgreementData}
            getCreateLabel={(query) => `+ Add ${query}`}
            onCreate={(query) => {
              setLegacyAgreementFormValue({
                ...legacyAgreementFormValue,
                typeOfAgreementData: [
                  ...legacyAgreementFormValue.typeOfAgreementData,
                  query,
                ],
              });
            }}
            onChange={(selectedOption) => {
              setLegacyAgreementFormValue({
                ...legacyAgreementFormValue,
                typeOfAgreement: selectedOption,
              });
            }}
            value={legacyAgreementFormValue.typeOfAgreement}
          />
          <Select
            searchable
            required
            creatable
            className="max-w-lg"
            label="Agreement Name"
            placeholder="Select Agreement Name"
            style={{ maxWidth: "82%" }}
            data={legacyAgreementFormValue.titleOfAgreementData}
            getCreateLabel={(query) => `+ Add ${query}`}
            onCreate={(query) => {
              setLegacyAgreementFormValue({
                ...legacyAgreementFormValue,
                titleOfAgreementData: [
                  ...legacyAgreementFormValue.titleOfAgreementData,
                  query,
                ],
              });
            }}
            onChange={(selectedOption) => {
              setLegacyAgreementFormValue({
                ...legacyAgreementFormValue,
                titleOfAgreement: selectedOption,
              });
            }}
            value={legacyAgreementFormValue.titleOfAgreement}
          />
        </div>
        <Divider className="mt-8" />
        <Text size="md" color="#46BDE1" className="my-8">
          term & location
        </Text>
        <div className="grid grid-cols-2 mt-4">
          <DatePicker
            required
            style={{ marginBottom: "20px", maxWidth: "82%" }}
            label="Starting Date"
            placeholder="Select Starting Date"
            onChange={(val) => {
              if (val && val.getTime) {
                setLegacyAgreementFormValue({
                  ...legacyAgreementFormValue,
                  dateOfContract: formatISO(val),
                });
              } else {
                setLegacyAgreementFormValue({
                  ...legacyAgreementFormValue,
                  dateOfContract: null,
                });
              }
            }}
            value={
              legacyAgreementFormValue.dateOfContract
                ? parseISO(legacyAgreementFormValue.dateOfContract)
                : null
            }
          />

          <div
            className="flex flex-row"
            style={{ marginBottom: "20px", maxWidth: "82%" }}
          >
            <Select
              required
              label="Duration"
              placeholder="Select years"
              data={[
                ...Array.from({ length: 11 }, (_, i) => ({
                  value: `${i}`,
                  label: `${i} year${i === 1 ? "" : "s"}`,
                })),
                { value: "100", label: "100 years" }, // Add the option for 100 years
              ]}
              onChange={(selectedOption) => {
                setLegacyAgreementFormValue({
                  ...legacyAgreementFormValue,
                  noOfYears: selectedOption,
                });
              }}
              value={legacyAgreementFormValue.noOfYears}
              style={{ width: "160px", marginRight: "20px" }}
            />
            <Select
              label="   "
              placeholder="Select months"
              data={[
                { value: "0", label: "0 month" },
                ...Array.from({ length: 12 }, (_, i) => ({
                  value: `${i + 1}`,
                  label: `${i + 1} month${i === 0 || i === 1 ? "" : "s"}`,
                })),
              ]}
              onChange={(selectedOption) => {
                setLegacyAgreementFormValue({
                  ...legacyAgreementFormValue,
                  noOfMonths: selectedOption,
                });
              }}
              value={legacyAgreementFormValue.noOfMonths}
              style={{ width: "160px" }}
            />
          </div>
          <Select
            style={{ marginBottom: "20px", maxWidth: "82%" }}
            placeholder="Reminder time"
            label="Renewal Reminder Time (in days)"
            data={[
              { value: 15, label: reminderLabels["15"] },
              { value: 30, label: reminderLabels["30"] },
              { value: 45, label: reminderLabels["45"] },
              { value: 60, label: reminderLabels["60"] },
            ]}
            onChange={(val) => {
              setLegacyAgreementFormValue({
                ...legacyAgreementFormValue,
                reminderTimeInDays: val,
              });
            }}
            value={legacyAgreementFormValue.reminderTimeInDays}
          />
          <Select
            style={{ marginBottom: "20px", maxWidth: "82%" }}
            required
            searchable
            placeholder="Enter location"
            label="Location"
            data={[...INDIA_STATES_UNIONS]}
            onChange={(val) => {
              setLegacyAgreementFormValue({
                ...legacyAgreementFormValue,
                location: val,
              });
            }}
            value={legacyAgreementFormValue.location}
          />
          <Select
            required
            searchable
            placeholder="Enter Business Unit"
            label="Business Unit"
            data={businessUnitsOptions}
            onChange={(val) => {
              setLegacyAgreementFormValue({
                ...legacyAgreementFormValue,
                businessUnit: val,
              });
            }}
            value={legacyAgreementFormValue.businessUnit}
            dropdownPosition="bottom"
            style={{ maxWidth: "82%" }}
          />
          {/* <TextInput required label="Location" placeholder='Enter location' {...legacyAgreementFormValue.getInputProps('location')}/> */}
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <div></div>
        <Button
          className="max-w-xs right mt-20"
          style={{
            backgroundColor: "#46BDE1",
          }}
          onClick={saveNext}
        >
          Next
        </Button>
      </div>
    </>
  );
};

export default LegacyAgreementForm1;
