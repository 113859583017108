import axios from 'axios';
import { headersProvider } from '../apiHelpers';
import constants from '../constants';

export const getAllClauseLibrary = () => axios.post(`${constants.utilityHost}/clauselibrary/getAll`, {}, {
    headers: headersProvider(),
});

export const createClauseLibrary = ({ title }) => axios.post(`${constants.utilityHost}/clauselibrary/create`, {
    title
}, {
    headers: headersProvider(),
});

export const createClause = ({ title, text, tags, clauseLibraryId }) => axios.post(`${constants.utilityHost}/clauselibrary/clause/create`, {
    title, text, tags, clauseLibraryId
}, {
    headers: headersProvider(),
});

export const getClauseLibraryById = ({ id }) => axios.get(`${constants.utilityHost}/clauselibrary/${id}`, {
    headers: headersProvider(),
});

export const getClauseById = ({ id }) => axios.get(`${constants.utilityHost}/clauselibrary/clause/${id}`, {
    headers: headersProvider(),
});

export const updateClauseById = ({ id, title, text, newTags, removedTags }) => axios.put(`${constants.utilityHost}/clauselibrary/clause/edit/${id}`, {
    title,
    text,
    newTags,
    removedTags
}, {
    headers: headersProvider(),
});


export const deleteClauseLibrary = ({ clauseLibraryId }) => axios.delete(`${constants.utilityHost}/clauselibrary/delete/${clauseLibraryId}`, {
    headers: headersProvider(),
});

export const deleteClause = ({ clauseId }) => axios.delete(`${constants.utilityHost}/clauselibrary/clause/delete/${clauseId}`, {
    headers: headersProvider(),
});



