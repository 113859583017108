import React, { useState } from 'react';
import {
  AppShell, Header, Navbar, ActionIcon, ScrollArea 
} from '@mantine/core';
import {
  Notes, Home, Paperclip,
  Bell, Template,
  LayoutSidebarLeftCollapse, LayoutSidebarRightCollapse, Snowman, ReportMoney,
} from 'tabler-icons-react';
// import {
//   Notes, Home, Paperclip,
//   Bell, BarrierBlock, Template,
//   LayoutSidebarLeftCollapse, LayoutSidebarRightCollapse, Snowman, ReportMoney,
// } from 'tabler-icons-react';
import { LinksGroup } from '../../components/NavbarLinkGroup';
import AppHeader from '../../components/Header/AppHeader';

const MainApp = ({ appContent }) => {
  const mockdata = [
    {
      label: 'Dashboard',
      icon: Home,
      link: '/app',
    },
    {
      label: 'Notifications',
      icon: Bell,
      link: '/app/notifications',
    },
    {
      label: 'Agreements Manager',
      icon: Paperclip,
      link: '/app/agreements',
    },
    {
      label: 'Disputes Manager',
      icon: Notes,
      initiallyOpened: true,
      links: [
        { label: 'Legal Notices', link: '/app/dispute-manager/legal-notices' },
        { label: 'Cases', link: '/app/dispute-manager/cases' },
      ],
    },
    // {
    //   label: 'Templates',
    //   icon: Template,
    //   link: '/app/templates',
    // },
    {
      label: 'Templates',
      icon: Template,
      initiallyOpened: true,
      links: [
        { label: 'Agreement', link: '/app/templates/agreement' },
        { label: 'Notice', link: '/app/templates/notice' },
        // { label: 'Documents', link: '/app/templates/document' },
        { label: 'Clause', link: '/app/templates/clause' },
      ],
    },
    {
      label: 'Team Manager',
      icon: Snowman,
      link: '/app/service-providers',
    },
    {
      label: 'Budget Manager',
      icon: ReportMoney,
      link: '/app/budget-manager',
    },
    {
      label: 'Blog Manager',
      icon: Notes,
      link: '/app/blog-manager',
    },
    // {
    //   label: 'For development',
    //   icon: BarrierBlock,
    //   initiallyOpened: false,
    //   links: [
    //     {
    //       label: 'Dashboard Old',
    //       icon: Home,
    //       link: '/app-old',
    //     },
    //     {
    //       label: 'New Mock Page',
    //       icon: Home,
    //       link: '/app/case-new-form',
    //     },
    //   ],
    // },
  ];

  const links = mockdata.map((item) => <LinksGroup {...item} key={item.label} />);

  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  return (
    <AppShell
      padding="md"
      navbar={(
        <Navbar
          style={{ backgroundColor: '#F1F4FA' }}
          width={{ base: sidebarCollapsed ? 50 : 300 }}
          height="calc(100vh - 65px)"
          p="xs"
        >
          <Navbar.Section grow component={ScrollArea}>
            <div className="flex flex-col">
              <div className="flex justify-end">
                <ActionIcon color="blue" onClick={() => setSidebarCollapsed(!sidebarCollapsed)}>
                  {sidebarCollapsed
                    ? <LayoutSidebarRightCollapse />
                    : <LayoutSidebarLeftCollapse />}
                </ActionIcon>
              </div>
              {!sidebarCollapsed && <div>{links}</div>}
            </div>
          </Navbar.Section>
        </Navbar>
          )}
      header={<Header height={65}><AppHeader /></Header>}
    >
      <div style={{ backgroundColor: '#FFFFFF', minHeight: 'calc(100vh - 100px)' }}>
        {appContent}
      </div>
    </AppShell>
  );
};

export default MainApp;
