import React, { useState } from "react";
import {
  AppShell,
  Header,
  Navbar,
  ActionIcon,
  ScrollArea,
} from "@mantine/core";
import {
  Notes,
  Signature,
  Pencil,
  Lock,
  Subtask,
  Home,
  Paperclip,
  Bell,
  Plus,
  Search,
  Template,
  LayoutSidebarLeftCollapse,
  LayoutSidebarRightCollapse,
  Snowman,
  ReportMoney,
} from "tabler-icons-react";
// import {
//   Notes, Home, Paperclip,
//   Bell, BarrierBlock, Template,
//   LayoutSidebarLeftCollapse, LayoutSidebarRightCollapse, Snowman, ReportMoney,
// } from 'tabler-icons-react';
import { LinksGroup } from "../../components/NavbarLinkGroup";
import AppHeader from "../../components/Header/AppHeader";
import HamburgerMenuAgreements from "../HamburgerMenuAgreements";
import { selectUserData } from "../../redux/selectors";
import { useSelector } from "react-redux";

const MainAppAgreementsManager = ({ appContent }) => {
  const currentUser = useSelector(selectUserData) || {};
  const roles = currentUser.userRole || [];

  const loginAs = currentUser.loginAs;

  const mockdata = [
    {
      label: "dashboard",
      icon: Home,
      link: "/app/agreements_manager",
    },
    {
      label: "notifications",
      icon: Bell,
      link: "/app/agreements_manager/notifications",
    },

    {
      label: "my agreements",
      icon: Paperclip,
      link: "/app/agreements_manager/agreements-list",
    },
    loginAs.id === 3 || loginAs.id === 4 || loginAs.id === 1 || loginAs.id === 2
      ? {
          label: "new agreement",
          icon: Plus,
          link: "/app/agreements_manager/create-new-agreement",
        }
      : null,
    // loginAs.id === 5 || loginAs.id === 6
    //   ? {
    //       label: "Review",
    //       icon: Search,
    //       link: "/app/agreements_manager/review",
    //     }
    //   : null,
    // loginAs.id === 1 || loginAs.id === 4
    //   ? {
    //       label: "Signature",
    //       icon: Pencil,
    //       link: "/app/agreements_manager/signature",
    //     }
    //   : null,
    loginAs.id === 1 || loginAs.id === 4 || loginAs.id === 2 ||loginAs.id === 3
      ? {
          label: "docs locker",
          icon: Lock,
          link: "/app/agreements_manager/docs-locker",
        }
      : null,
    loginAs.id === 1 || loginAs.id === 2 || loginAs.id === 4
      ? {
          label: "templates",
          icon: Template,
          initiallyOpened: true,
          links: [
            {
              label: "agreements",
              link: "/app/agreements_manager/templates/agreement",
            },
            {
              label: "clauses",
              link: "/app/agreements_manager/templates/clause",
            },
          ],
        }
      : null,
      loginAs.id === 1 || loginAs.id === 2 || loginAs.id === 4?
    {
      label: "obligations",
      icon: Subtask,
      initiallyOpened: true,
      link: "https://obligationapp.truecounsel.in/",

    }: null,

    loginAs.id === 1 || loginAs.id === 2
      ? {
          label: "team manager",
          icon: Snowman,
          link: "/app/agreements_manager/service-providers",
        }
      : null,
  ].filter(Boolean);

  const links = mockdata.map((item) => (
    <LinksGroup {...item} key={item.label} />
  ));

  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  return (
    <AppShell
      padding="md"
      navbar={
        <Navbar
          style={{ backgroundColor: "#F1F4FA", padding: "10px 0 0 0" }}
          width={{ base: sidebarCollapsed ? 50 : 250 }}
          height="calc(100vh - 65px)"
          p="xs"
        >
          <Navbar.Section grow component={ScrollArea}>
            <div className="flex flex-col">
              <div className="flex justify-end">
                <ActionIcon
                  color="blue"
                  onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
                  style={{ cursor: "pointer", marginRight: "9%" }}
                >
                  {sidebarCollapsed ? (
                    <LayoutSidebarRightCollapse />
                  ) : (
                    <LayoutSidebarLeftCollapse />
                  )}
                </ActionIcon>
              </div>
              {!sidebarCollapsed && <div>{links}</div>}
            </div>
          </Navbar.Section>
        </Navbar>
      }
      header={
        <Header height={65}>
          <AppHeader />
        </Header>
      }
    >
      <div
        style={{ backgroundColor: "#FFFFFF", minHeight: "calc(100vh - 100px)" }}
      >
        {appContent}
      </div>
    </AppShell>
  );
};

export default MainAppAgreementsManager;
