/** @format */

import React, { useEffect, useState } from "react";
import { Button } from "@mantine/core";
import flat from "flat";
import { BeatLoader } from "react-spinners";
import { loadingStates, validateEmail } from "../../utilities/utilities";
import colors from "../../utilities/design";
import { showNotification } from "@mantine/notifications";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { getPartiesList, getWorkSpaceLists } from "../../utilities/apis/parties";
import AgreementParty from "./AgreementParty";
import userPartyCreateHook from "../../hooks/usePartyCreate";
import LegacyAgreementParty from "./LegacyAgreementParty";

const LegacyAgreementForm3 = ({
  setLegacyAgreementFormValue,
  legacyAgreementFormValue,
  uiConfigs,
  setUiConfigs,
  saveAgreement,
}) => {
  
  console.log({ legacyAgreementFormValue });
  const [dropDownNames, setDropDownNames] = useState([]);
  const [parties, setParties] = useState([]);
  // const[name, setName]= useState("");

  
  
  const partiesApi = async (name) => {
    const { data: res } = await apiWrapWithErrorWithData(
      getPartiesList({ name })
    );
    setParties(res);
    const names = res.map(({ name: val }) => val);
    setDropDownNames(names);
  };
  useEffect(() => {
    partiesApi();
  }, [])
  
  console.log(dropDownNames)
  

  const validate = () => {
    const keys = {};
    if (
      legacyAgreementFormValue.secondParty.type === "Entity" &&
      legacyAgreementFormValue.firstParty.type === "Entity"
    ) {
      if (
        !legacyAgreementFormValue.firstParty.entityType ||
        legacyAgreementFormValue.firstParty.entityType?.length < 3
      ) {
        keys["firstParty.entityType"] = "Please fill Entity Type.";
      }
      else if (
        !legacyAgreementFormValue.secondParty.entityType ||
        legacyAgreementFormValue.secondParty.entityType?.length < 3
      ) {
        keys["secondParty.entityType"] = "Please fill Entity Type.";
      }
      else if (
        !legacyAgreementFormValue.firstParty.name ||
        legacyAgreementFormValue.firstParty.name?.length < 3
      ) {
        keys["firstParty.name"] = "Please enter 3 or more characters.";
      }
      else if (
        !legacyAgreementFormValue.secondParty.name ||
        legacyAgreementFormValue.secondParty.name?.length < 3
      ) {
        keys["secondParty.name"] = "Please enter 3 or more characters.";
      }
      
    }
    if (
      legacyAgreementFormValue.secondParty.type === "Individual" &&
      legacyAgreementFormValue.firstParty.type === "Individual"
    ) {
      if (
        !legacyAgreementFormValue.firstParty.indi_name ||
        legacyAgreementFormValue.firstParty.indi_name?.length < 3
      ) {
        keys["firstParty.indi_name"] = "Please enter 3 or more characters.";
      }
      else if (
        !legacyAgreementFormValue.secondParty.indi_name ||
        legacyAgreementFormValue.secondParty.indi_name?.length < 3
      ) {
        keys["secondParty.indi_name"] = "Please enter 3 or more characters.";
      }
    }
    if (
      legacyAgreementFormValue.secondParty.type === "Individual" &&
      legacyAgreementFormValue.firstParty.type === "Entity"
    ) {
      if (
        !legacyAgreementFormValue.firstParty.entityType ||
        legacyAgreementFormValue.firstParty.entityType?.length < 3
      ) {
        keys["firstParty.entityType"] = "Please fill Entity Type.";
      }
      else if (
        !legacyAgreementFormValue.firstParty.name ||
        legacyAgreementFormValue.firstParty.name?.length < 3
      ) {
        keys["firstParty.name"] = "Please enter 3 or more characters.";
      }
      else if (
        !legacyAgreementFormValue.secondParty.indi_name ||
        legacyAgreementFormValue.secondParty.indi_name?.length < 3
      ) {
        keys["secondParty.indi_name"] = "Please enter 3 or more characters.";
      }
    }
     if (
      legacyAgreementFormValue.firstParty.type === "Individual" &&
      legacyAgreementFormValue.secondParty.type === "Entity"
    ) {
      if (
        !legacyAgreementFormValue.secondParty.entityType ||
        legacyAgreementFormValue.secondParty.entityType?.length < 3
      ) {
        keys["secondParty.entityType"] = "Please fill Entity Type.";
      }
      else if (
        !legacyAgreementFormValue.secondParty.name ||
        legacyAgreementFormValue.secondParty.name?.length < 3
      ) {
        keys["secondParty.name"] = "Please enter 3 or more characters.";
      }
      else if (
        !legacyAgreementFormValue.firstParty.indi_name ||
        legacyAgreementFormValue.firstParty.indi_name?.length < 3
      ) {
        keys["firstParty.indi_name"] = "Please enter 3 or more characters.";
      }
    }

    if (Object.keys(keys).length !== 0) {
      
      showNotification({
        color: "red",
        title: "Agreement Form",
        message: "Please check all fields are filled properly.",
      });
    }
    return Object.keys(keys).length === 0;
  };

  const savePrev = async () => {
    
    setUiConfigs({
      ...uiConfigs,
      currentStep: 0,
    });
  };

  const saveNext = async () => {
    if (validate()) {
      await saveAgreement();
      setUiConfigs({
        ...uiConfigs,
        currentStep: 2,
      });
    }
  };

  return (
    <div className="w-full flex flex-col my-4">
      <div className="flex flex-col justify-between my-2">
        <LegacyAgreementParty
          legacyAgreementFormValue={legacyAgreementFormValue}
          dropDownNames={dropDownNames}
          setLegacyAgreementFormValue={setLegacyAgreementFormValue}
          partyName={"firstParty"}
          setDropDownNames={setDropDownNames}
          // setName={setName}
        />

        <LegacyAgreementParty
          legacyAgreementFormValue={legacyAgreementFormValue}
          dropDownNames={dropDownNames}
          setLegacyAgreementFormValue={setLegacyAgreementFormValue}
          partyName={"secondParty"}
          setDropDownNames={setDropDownNames}
          // setName={setName}
        />
        {/* {[1, 2, 3].includes(addParty) ? (
          <AgreementParty
            legacyAgreementFormValue={legacyAgreementFormValue}
            dropDownNames={dropDownNames}
            form={form}
            partyName={"thirdParty"}
            setDropDownNames={setDropDownNames}
          />
        ) : null}
        {[2, 3].includes(addParty) ? (
          <AgreementParty
            legacyAgreementFormValue={legacyAgreementFormValue}
            dropDownNames={dropDownNames}
            form={form}
            partyName={"fourthParty"}
            setDropDownNames={setDropDownNames}
          />
        ) : null}
        {[3].includes(addParty) ? (
          <AgreementParty
            legacyAgreementFormValue={legacyAgreementFormValue}
            dropDownNames={dropDownNames}
            form={form}
            partyName={"fifthParty"}
            setDropDownNames={setDropDownNames}
          />
        ) : null} */}
      </div>
      {/* <div className="flex justify-end">
        //{addParty < 3 ? (
          <Button onClick={() => setAddParty((pre) => pre + 1)}>
            Add Party
          </Button>
        ) : null}
      </div> */}
      <div className="flex flex-row justify-between">
        {uiConfigs.loading === loadingStates.LOADING ? (
          <BeatLoader color={colors.primary} size={10} />
        ) : (
          <>
            <Button
              className="max-w-xs mt-20"
              onClick={savePrev}
              style={{
                backgroundColor: "#46BDE1",
              }}
            >
              Previous
            </Button>
            <Button
              className="max-w-xs mt-20"
              onClick={saveNext}
              style={{
                backgroundColor: "#46BDE1",
              }}
            >
              Next
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default LegacyAgreementForm3;
