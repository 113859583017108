/** @format */

import React, { useEffect, useState } from "react";
import {
  getValueForInput,
  loadingStates,
  csvDownload,
  formatDate,
} from "../../utilities/utilities";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import {
  getAgreementsList,
  getAgreementTypesApi,
  getTotalAgreementsList,
  getAgreementsCsvDownload,
} from "../../utilities/apis/agreements";
import AgreementGraph from "./AgreementGraph";
import HamburgerMenuAgreements from "../HamburgerMenuAgreements";
import AgreementGraph1 from "./AgreementGraph1";
import AgreementAnalytics1 from "../../components/AgreementAnalytics/AgreementAnalytics1";

const statusOptions = [
  {
    label: "Pending with Company",
    value: "Pending with Company",
  },
  {
    label: "TP",
    value: "TP",
  },
];

const estampOption = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];

const AgreementsAllStatus = () => {
  const [year, setYear] = useState(2024);
  const [configs, setConfigs] = useState({
    loading: loadingStates.LOADING,
    data: null,
  });
  const [filters, setFilter] = useState({
    typeOfAgreement: null,
    mapBusinessunit: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        let filtersForData = await Object.fromEntries(
          Object.entries(filters).filter(([key, value]) => value !== null)
        );
        const response = await apiWrapWithErrorWithData(
          getTotalAgreementsList({year:year, filterOptions: filtersForData })
        );
        console.log("response is: ", response);
        if (response) {
          setConfigs({
            loading: loadingStates.NO_ACTIVE_REQUEST,
            data: response,
          });
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchData();
  }, [filters, year]);
  console.log("configs: ", configs);
  console.log("filters: ", filters);

  return (
    <>
      <div
        className="flex"
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "12px",
        }}
      >
        <h1
          style={{
            fontSize: "22px",
            fontWeight: "600",
            letterSpacing: "1px",
            fontFamily: "lexend",
            whiteSpace: "nowrap",
            paddingBottom: "13px",

            position: "relative",
            color: "black",
            // margin: "0 auto"
          }}
        >
          total agreements
        </h1>

        <HamburgerMenuAgreements />
      </div>
      <div className="flex flex-row flex-wrap w-full">
        <AgreementAnalytics1
          filter={filters}
          setFilter={setFilter}
          configs={configs}
        />
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <AgreementGraph year={year} setYear={setYear} configs={configs} />
        <AgreementGraph1 configs={configs} />
      </div>
    </>
  );
};

export default AgreementsAllStatus;
