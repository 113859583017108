/** @format */

import React from "react";
import { Button, Modal, TextInput, Textarea } from "@mantine/core";
import { MultiSelect } from "@mantine/core";

export function CreateClauseModal(props) {
  return (
    <Modal
      opened={props.clauseModalConfig.open}
      onClose={() => {
        props.setEditClauseData({});
        props.setCreationClause({ ...props.clauseTemplate });
        props.setClauseModalConfig((preState) => ({
          ...preState,
          open: false,
        }));
      }}
      size="calc(70vw)"
    >
      <TextInput
        label="Clause Name"
        value={props.creationClause.title}
        onChange={(e) =>
          props.setCreationClause((pre) => ({ ...pre, title: e.target.value }))
        }
        placeholder="Enter clause name"
      />
      <Textarea
        minRows={10}
        label="Text"
        value={props.creationClause.text}
        onChange={(e) => {
          props.setCreationClause((preState) => ({
            ...preState,
            text: e.target.value,
          }));
        }}
        placeholder="Copy paste the text"
      />
      <MultiSelect
        className="my-2"
        label="Tags"
        placeholder="Enter tags"
        searchable
        creatable
        defaultValue={
          props.creationClause.tags?.map(({ value }) => value) || []
        }
        data={props.creationClause.tags || []}
        getCreateLabel={(query) => `+ ${query}`}
        onCreate={(query) => {
          const item = {
            value: query,
            label: query,
          };
          props.setCreationClause((preState) => ({
            ...preState,
            tags: [...preState.tags, query],
          }));
          return item;
        }}
        onChange={(arr) =>
          props.setCreationClause((preState) => ({
            ...preState,
            tags: [...arr],
          }))
        }
      />
      <div className="flex justify-end my-4">
        <Button
          disabled={props.clauseModalConfig.buttonLoading}
          onClick={props.createClauseApi}
        >
          submit
        </Button>
      </div>
    </Modal>
  );
}
